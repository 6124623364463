.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  padding: 5px 12px 6px;
  text-align: center;
  text-shadow: 0 1px 0 rgb(255 255 255 / 40%);
  border-radius: 7.5px;
  flex-direction: row;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 13%);
  background-color: rgba(225,245,254,0.92);
  
  /* display: flex;
  justify-content: center; */

  /* background-color: aqua; */
  /* color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase; */
}
.styck {
  position: sticky;
  top: 0em;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  word-wrap: break-word;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #dcf8c6;
  /* color: white; */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.float-right{
  height: 15px;
  font-size: 9px;
  line-height: 15px;
  color: #555;
  white-space: nowrap;

  float: right;
  margin: 9px 0 -5px 4px;
}