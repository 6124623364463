.confirm-button-group {
  position: absolute;
  bottom: 0;
}

.confirm-button {
  z-index: 100;
}

.videoview {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
  top: 0;
  left: 0;
}
.videoview > .label {

  z-index: 200;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15%;

  padding: 0.8em !important;
  text-align: center;
  font-weight: 400 !important;
  font-size: 1.5rem !important;
  /* background-color: @whiteSmoke; */
  color: #fff;
}
.videoview > .controls {
  position: absolute;
  z-index: 100;
  top: 10%;
  right: 0;
}
.videoview > .controls > button {
  background-color: transparent !important;
  /* color: @darkblue !important; */
}
.videocontainer {
  position: absolute;
  height: 100%;
  top: 0;
  width: 3000px;
  left: -1500px;
  margin-left: 50%;
  text-align: center;
}
#video {
  position: relative;
  height: 100%;
  z-index: 1;
  margin: 0 auto;
}

.hoverer {
  position: absolute;
  left: -1400px;
  top: -1400px;
  margin-left: 50%;
  height: 200px;
  width: 200px;
  z-index: 10;
  /* linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%) */
  border-left: 1400px solid rgba(22, 28, 36, 0.48);
  border-right: 1400px solid rgba(22, 28, 36, 0.48);
  border-bottom: 1400px solid rgba(22, 28, 36, 0.48);
  border-top: 1400px solid rgba(22, 28, 36, 0.48);
  box-sizing: unset;
}
.hoverer > .hoverer-border {
  width: 100%;
  height: 100%;
  border: 8px solid rgba(17, 53, 88, 0.16);
  box-sizing: border-box;
  text-align: center;
  padding-top: 'calc(100% - 24px)';
}
.scanner-controls {
  z-index: 100;
  position: absolute !important;
  bottom: 2em;
  width: 100%;
}
.scanner-controls > .button {
  width: 160px;
}
.videocontainer {
  position: absolute;
  height: 100%;
  top: 0;
  width: 3000px;
  left: -1500px;
  margin-left: 50%;
  text-align: center;
  border-radius: 10px
}

@keyframes laser {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -50%);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0, 50%);
  }
}
.laser {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, transparent 49%, #d9464c 50%, transparent 51%);
}
.laser.active {
  -webkit-animation: laser 4s linear;
  animation: laser 4s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.back {
  position: fixed;
    top: 1em;
    z-index: inherit;
    left: 1em;
    color: #fff;
}




@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes pulse {
  0% {
    /* transform: scale(0.5); */
    /* opacity: 0.5; */
  }
  50% {
    /* opacity: 1; */
    width: 40px;
   height: 40px;
  }
  100% {
    /* transform: scale(1.2); */
    /* opacity: 0.5; */
  }
}

.a {
  position: absolute;
  box-sizing: border-box;
  border: 5px solid transparent;
  /* width: 10px;
  height: 10px; */
  width: 20px;
  height: 20px;
  border-color: #ffffff;
  animation: pulse 2s infinite;
  transition: .3s;
}
.a1 {top:0; left:0; border-right: none; border-bottom: none;}
.a2 {top:0; right:0; border-left: none; border-bottom: none;}
.a3 {bottom:0; right:0; border-top: none; border-left: none;}
.a4 {bottom:0; left:0; border-top: none; border-right: none;}

.parent {
  position: relative;
  float: left;
}
.parent:hover .a {
   width: 20px;
  height: 20px;
  border-color: #337AB7;
}