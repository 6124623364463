
@font-face {
  font-family: "OpenSans";
  /* src: url("../src/fonts/OpenSans.ttf"); */
  src: url("./fonts/OpenSans.ttf");
  src: url("./fonts/OpenSans.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans.ttf") format("ttf"),
  url("./fonts/OpenSans.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: "OpenSans-Light";
  src: url("./fonts/OpenSans-Light.ttf");
  src: url("./fonts/OpenSans-Light.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-Light.ttf") format("ttf"),
  url("./fonts/OpenSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
 

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./fonts/OpenSans-Bold.ttf");
  src: url("./fonts/OpenSans-Bold.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-Bold.ttf") format("ttf"),
  url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 


@font-face {
  font-family: "OpenSans-BoldItalic";
  src: url("./fonts/OpenSans-BoldItalic.ttf");
  src: url("./fonts/OpenSans-BoldItalic.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-BoldItalic.ttf") format("ttf"),
  url("./fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 


@font-face {
  font-family: "OpenSans-Italic";
  src: url("./fonts/OpenSans-Italic.ttf");
  src: url("./fonts/OpenSans-Italic.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-Italic.ttf") format("ttf"),
  url("./fonts/OpenSans-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 


@font-face {
  font-family: "OpenSans-LightItalic";
  src: url("./fonts/OpenSans-LightItalic.ttf");
  src: url("./fonts/OpenSans-LightItalic.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-LightItalic.ttf") format("ttf"),
  url("./fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 


@font-face {
  font-family: "OpenSans-Regular_0";
  src: url("./fonts/OpenSans-Regular_0.ttf");
  src: url("./fonts/OpenSans-Regular_0.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-Regular_0.ttf") format("ttf"),
  url("./fonts/OpenSans-Regular_0.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 


@font-face {
  font-family: "OpenSans-Semibold";
  src: url("./fonts/OpenSans-Semibold.ttf");
  src: url("./fonts/OpenSans-Semibold.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/OpenSans-Semibold.ttf") format("ttf"),
  url("./fonts/OpenSans-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
} 

@font-face {
  font-family: "Stem-SemiLight";
  src: url("./fonts/Stem-SemiLight.ttf");
  src: url("./fonts/Stem-SemiLight.ttf?#iefix") format("embedded-opentype"),
  url("./fonts/Stem-SemiLight.ttf") format("ttf"),
  url("./fonts/Stem-SemiLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}



.preloader-5 {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 30px auto;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #337AB7;
  animation: preloader-5-spin 2s linear infinite;
}


.preloader-5:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #BFE2FF;
  animation: preloader-5-spin 3s linear infinite;
}


.preloader-5:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #337AB7;
  animation: preloader-5-spin 1.5s linear infinite;
}


@keyframes preloader-5-spin {
  0%   {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
/* @media screen and (orientation:landscape) and (max-device-width: 900px) {
  html{
      -webkit-transform: rotate(-90deg);
         -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
           -o-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: left top;
         -moz-transform-origin: left top;
          -ms-transform-origin: left top;
           -o-transform-origin: left top;
              transform-origin: left top;
              width: 100vh !important;
     position: absolute;
      top: 100%;
      left: 0
  }
} */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) { 
  body {
  -webkit-transform: rotate(90deg);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; 
  }} */